exports.initialize_parameters = function(){
    let userSearchedParams = {
        minprice:null,
        maxprice:null,
		minsqm:null,
		maxsqm:null,
		location:null,
		bedrooms:null,
		bathrooms:null,
		property_type:null,
		floor:null,
		sale_type:null,
		furnitured:null,
		heating_type:null,
		minbuiltyear:null,
		parking:null
    };
    return userSearchedParams;
}